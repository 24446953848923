<template>
  <v-container fluid :style="{ backgroundColor: cliente.corfundo }" v-if="cliente.tipofundo=='C'">
    <v-row justify="center" align="center">
      <v-col align="center" sm="12" md="10" xl="8">
        <v-col>
          <v-avatar 
            tile 
            width="1" 
            height="1"
            class="mt-6 mb-5"
            v-if="((this.cliente.imagem == null) || (this.cliente.imagem == ''))"
          >
            <img :src="require('@/assets/logovazia.png')" />
          </v-avatar>
          <v-avatar 
            tile 
            width="150" 
            height="150"
            class="mb-5"
            v-if="((this.cliente.imagem !=null) && (this.cliente.imagem !='')) && (this.cliente.estiloimagem == '3')"
          >
            <img :src="imagem" />
          </v-avatar>
          <v-avatar 
            tile 
            width="250" 
            height="150"
            class="mb-5"
            v-if="((this.cliente.imagem !=null) && (this.cliente.imagem !='')) && (this.cliente.estiloimagem == '5')"
          >
            <img :src="imagem" />
          </v-avatar>
          <v-avatar 
            tile 
            width="270" 
            height="54"
            class="mb-5"
            v-if="((this.cliente.imagem !=null) && (this.cliente.imagem !='')) && (this.cliente.estiloimagem == '7')"
          >
            <img :src="imagem" />
          </v-avatar>
        </v-col>

        <v-card
          class="mx-auto mb-2 buttoncolor roundedbutton"
          :style="{ color: cliente.corfonte }"
          outlined
          max-width="600"
        >
          <v-col>
            <div class="text-h6 font-italic font-weight-bold" v-if="((this.cliente.nome !=null) && (this.cliente.nome !=''))">{{ cliente.nome.substr(0, 26) }}</div>
            <div class="text-h6 font-italic font-weight-bold" v-else><br /></div>
            <div class="text-h8" v-if="((this.cliente.cargo !=null) && (this.cliente.cargo !=''))">{{ cliente.cargo.substr(0, 40) }}</div>
            <div class="text-h8" v-else><br /></div>
          </v-col>
          <v-col class="textsize font-weight-bold margemtext">
            <div class="text-h8" v-if="((this.cliente.empresa !=null) && (this.cliente.empresa !=''))">{{ cliente.empresa.substr(0, 40) }}</div>
            <div class="text-h8" v-else><br /></div>
          </v-col>
        </v-card>

        <v-card color="transparent" flat max-width="600">
          <v-card-text 
            :style="{ color: cliente.corfonte }" 
            class="textbox multiline mt-5 mb-3"
            
            v-if="cliente.texto != null && cliente.texto.trim() != ''"
          >
            {{ cliente.texto }}
          </v-card-text>
          <div v-else><br /></div>
        </v-card>

        <a
          @click="adicionarContato"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large  color="white">mdi-account-box</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Adicionar Contato
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <v-speed-dial v-model="dialShare" direction="botton" open-on-click>
          <template v-slot:activator>
            <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
              outlined
              max-width="600"
            >
              <v-row>
                <v-col cols="1">
                  <v-icon class="mt-2" large color="white">mdi-share-variant</v-icon>
                </v-col>
                <v-col cols="11">
                  <v-card-text class="textsize">
                    <v-text v-if="dialShare">Fechar</v-text>
                    <v-text v-else>Compartilhar</v-text>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <v-card>
            <v-col>
              <v-btn dark fab bottom color="green" small :href="`whatsapp://send?text=${formataTexto('w')}`" target="_blank">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn dark fab bottom color="blue" small :href="`https://www.facebook.com/sharer/sharer.php?u=${linkconsulta}`" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn dark fab bottom color="blue darken-7" small :href="`https://www.linkedin.com/sharing/share-offsite?url=${linkconsulta}`" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <v-btn dark fab bottom color="tertiary" small :href="`mailto:?subject=Meu Cartão de Visitas!&amp;body=${formataTexto('e')}`" target="_blank">
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </v-col>
          </v-card>
        </v-speed-dial>
  
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          color="red"
          shaped
          elevation="24"
          centered
          h2--text
        >
          <h1>{{ text }}</h1>
        </v-snackbar>

        <a
          :href="cliente.google1"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.endereco1 != null && cliente.endereco1.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-map-marker</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nossa Localização
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          :href="cliente.google2"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.endereco2 != null && cliente.endereco2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-map-marker</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nossa Localização II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          :href="cliente.google3"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.endereco3 != null && cliente.endereco3.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-map-marker</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nossa Localização III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonefixo1" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonefixo1 != null && cliente.telefonefixo1.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone Principal
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonefixo2" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonefixo2 != null && cliente.telefonefixo2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonefixo3" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonefixo3 != null && cliente.telefonefixo3.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonenacional" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonenacional != null && cliente.telefonenacional.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone-incoming</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone Nacional
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonecelular1" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonecelular1 != null && cliente.telefonecelular1.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-cellphone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Celular
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonecelular2" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonecelular2 != null && cliente.telefonecelular2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-cellphone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Celular II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonecelular3" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonecelular3 != null && cliente.telefonecelular3.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-cellphone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Celular III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'mailto:' + cliente.email" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.email != null && cliente.email.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-email</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  E-mail
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'mailto:' + cliente.email2" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.email2 != null && cliente.email2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-email</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  E-mail II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="cliente.whatsapp1 != null && cliente.whatsapp1.trim() != ''"
          :href="
            'https://wa.me/' +
            cliente.whatsapp1
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('.', '')
          "
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-whatsapp</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Whatsapp
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="cliente.whatsapp2 != null && cliente.whatsapp2.trim() != ''"
          :href="
            'https://wa.me/' +
            cliente.whatsapp2
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('.', '')
          "
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-whatsapp</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Whatsapp II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="cliente.whatsapp3 != null && cliente.whatsapp3.trim() != ''"
          :href="
            'https://wa.me/' +
            cliente.whatsapp3
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('.', '')
          "
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-whatsapp</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Whatsapp III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.website !=null) && (this.cliente.website !='')"
          :href="cliente.website"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-web</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nosso Site
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.facebook !=null) && (this.cliente.facebook !='')"
          :href="cliente.facebook"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-facebook</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Facebook
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.facebook2 !=null) && (this.cliente.facebook2 !='')"
          :href="cliente.facebook2"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-facebook</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Facebook II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.instagram !=null) && (this.cliente.instagram !='')"
          :href="cliente.instagram"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-instagram</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Instagram
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.instagram2 !=null) && (this.cliente.instagram2 !='')"
          :href="cliente.instagram2"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-instagram</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Instagram II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.twitter !=null) && (this.cliente.twitter !='')"
          :href="cliente.twitter" 
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-twitter</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Twitter
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.youtube !=null) && (this.cliente.youtube !='')"
          :href="cliente.youtube"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-youtube</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Youtube
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.linkedin !=null) && (this.cliente.linkedin !='')"
          :href="cliente.linkedin"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-linkedin</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Linkedin
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.tiktok !=null) && (this.cliente.tiktok !='')"
          :href="cliente.tiktok"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/tiktok-icon.png')"
                  width="28"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  TikTok
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.olx !=null) && (this.cliente.olx !='')"
          :href="cliente.olx"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/olx-icon.png')"
                  width="30"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  OLX
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.mercadolivre !=null) && (this.cliente.mercadolivre !='')"
          :href="cliente.mercadolivre"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/mercadolivre-icon.png')"
                  width="30"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Mercado Livre
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.shopee !=null) && (this.cliente.shopee !='')"
          :href="cliente.shopee"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/shopee-icon.png')"
                  width="30"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Shopee
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.galeriafotos !=null) && (this.cliente.galeriafotos !='')"
          :href="cliente.galeriafotos"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-camera</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Galeria de Fotos
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.cardapio !=null) && (this.cliente.cardapio !='')"
          :href="cliente.cardapio"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-book-open-variant</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nosso Cardápio
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.ifood !=null) && (this.cliente.ifood !='')"
          :href="cliente.ifood"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/ifood-icon.png')"
                  width="60"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  IFood
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.visitavirtual !=null) && (this.cliente.visitavirtual !='')"
          :href="cliente.visitavirtual"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-rotate-360</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Tour Virtual
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.avaliacao !=null) && (this.cliente.avaliacao !='')"
          @click="abrirAvaliacao"
        >
          <v-card 
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-thumb-up</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                      Avalie a Empresa
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <v-card
          v-if="(this.cliente.pixcopiaecola !=null) && (this.cliente.pix !='')"
          class="mx-auto mb-2 buttoncolor"
          :style="{ color: cliente.corfonte }"
          outlined
          max-width="600"
          v-clipboard="this.cliente.pixcopiaecola"
          v-clipboard:success="clipboardSuccess"
          v-clipboard:error="clipboardError"
        >
          <v-row>
            <v-col cols="1">
              <img 
                class="mt-3 ml-1"
                :src="require('@/assets/pix-icon.png')"
                width="30"
              />
            </v-col>
            <v-col cols="11">
              <v-card-text class="textsize">
                PIX Copia e Cola
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          class="mx-auto mb-2 buttoncolor"
          :style="{ color: cliente.corfonte }"
          max-width="600"
          outlined
          v-if="(((this.cliente.appapple !=null) && (this.cliente.appapple !='')) || ((this.cliente.appandroid !=null) && (this.cliente.appandroid !='')))"
        >
          <v-card-title>
            <!-- <v-icon large left> mdi-download </v-icon> -->
            <span class="textsize font-weight-light">Baixe nosso aplicativo</span>
          </v-card-title>

          <v-card-text class="textsize">
            <v-row>
            <v-col cols="6"> 
              <a
                :href="cliente.appapple"
                target="_blank"
              >
                <img 
                  :src="require('@/assets/applestore.png')"
                  width="40"
                />
              </a>
            </v-col>

            <v-col cols="6"> 
              <a
                :href="cliente.appandroid"
                target="_blank"
              >
                <img 
                  :src="require('@/assets/googleplay.png')"
                  width="40"
                />
              </a>
            </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <a 
          href="http://www.infinitycard.net.br"
          target="_blank"
        >
          <div>
            <img 
              class="mr-auto ml-auto mt-3"
              :src="require('@/assets/logobranco.png')"
              width="100"
            />
          </div>
          <div class="textlogo white--text">
            Clique e entre em contato
          </div>
        </a>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid class="bgcolor" v-else>
    <v-row justify="center" align="center">
      <v-col align="center" sm="12" md="10" xl="8">
        <v-col>
          <v-avatar 
            tile 
            width="1" 
            height="1"
            class="mt-6 mb-5"
            v-if="((this.cliente.imagem == null) || (this.cliente.imagem == ''))"
          >
            <img :src="require('@/assets/logovazia.png')" />
          </v-avatar>
          <v-avatar 
            tile 
            width="150" 
            height="150"
            class="mb-5"
            v-if="((this.cliente.imagem !=null) && (this.cliente.imagem !='')) && (this.cliente.estiloimagem == '3')"
          >
            <img :src="imagem" />
          </v-avatar>
          <v-avatar 
            tile 
            width="250" 
            height="150"
            class="mb-5"
            v-if="((this.cliente.imagem !=null) && (this.cliente.imagem !='')) && (this.cliente.estiloimagem == '5')"
          >
            <img :src="imagem" />
          </v-avatar>
          <v-avatar 
            tile 
            width="270" 
            height="54"
            class="mb-5"
            v-if="((this.cliente.imagem !=null) && (this.cliente.imagem !='')) && (this.cliente.estiloimagem == '7')"
          >
            <img :src="imagem" />
          </v-avatar>
        </v-col>

        <v-card
          class="mx-auto mb-2 buttoncolor roundedbutton"
          :style="{ color: cliente.corfonte }"
          outlined
          max-width="600"
        >
          <v-col>
            <div class="text-h6 font-italic font-weight-bold" v-if="((this.cliente.nome !=null) && (this.cliente.nome !=''))">{{ cliente.nome.substr(0, 26) }}</div>
            <div class="text-h6 font-italic font-weight-bold" v-else><br /></div>
            <div class="text-h8" v-if="((this.cliente.cargo !=null) && (this.cliente.cargo !=''))">{{ cliente.cargo.substr(0, 40) }}</div>
            <div class="text-h8" v-else><br /></div>
          </v-col>
          <v-col class="textsize font-weight-bold margemtext">
            <div class="text-h8" v-if="((this.cliente.empresa !=null) && (this.cliente.empresa !=''))">{{ cliente.empresa.substr(0, 40) }}</div>
            <div class="text-h8" v-else><br /></div>
          </v-col>
        </v-card>

        <v-card color="transparent" flat max-width="600">
          <v-card-text 
            :style="{ color: cliente.corfonte }" 
            class="textbox multiline mt-5 mb-3"
            
            v-if="cliente.texto != null && cliente.texto.trim() != ''"
          >
            {{ cliente.texto }}
          </v-card-text>
          <div v-else><br /></div>
        </v-card>

        <a
          @click="adicionarContato"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large  color="white">mdi-account-box</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Adicionar Contato
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <v-speed-dial v-model="dialShare" direction="botton" open-on-click>
          <template v-slot:activator>
            <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
              outlined
              max-width="600"
            >
              <v-row>
                <v-col cols="1">
                  <v-icon class="mt-2" large color="white">mdi-share-variant</v-icon>
                </v-col>
                <v-col cols="11">
                  <v-card-text class="textsize">
                    <v-text v-if="dialShare">Fechar</v-text>
                    <v-text v-else>Compartilhar</v-text>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <v-card>
            <v-col>
              <v-btn dark fab bottom color="green" small :href="`whatsapp://send?text=${formataTexto('w')}`" target="_blank">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn dark fab bottom color="blue" small :href="`https://www.facebook.com/sharer/sharer.php?u=${linkconsulta}`" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn dark fab bottom color="blue darken-7" small :href="`https://www.linkedin.com/sharing/share-offsite?url=${linkconsulta}`" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <v-btn dark fab bottom color="tertiary" small :href="`mailto:?subject=Meu Cartão de Visitas!&amp;body=${formataTexto('e')}`" target="_blank">
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </v-col>
          </v-card>
        </v-speed-dial>
  
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          color="red"
          shaped
          elevation="24"
          centered
          h2--text
        >
          <h1>{{ text }}</h1>
        </v-snackbar>

        <a
          :href="cliente.google1"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.endereco1 != null && cliente.endereco1.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-map-marker</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nossa Localização
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          :href="cliente.google2"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.endereco2 != null && cliente.endereco2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-map-marker</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nossa Localização II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          :href="cliente.google3"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.endereco3 != null && cliente.endereco3.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-map-marker</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nossa Localização III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonefixo1" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonefixo1 != null && cliente.telefonefixo1.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone Principal
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonefixo2" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonefixo2 != null && cliente.telefonefixo2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonefixo3" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonefixo3 != null && cliente.telefonefixo3.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonenacional" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonenacional != null && cliente.telefonenacional.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-phone-incoming</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Telefone Nacional
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonecelular1" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonecelular1 != null && cliente.telefonecelular1.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-cellphone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Celular
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonecelular2" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonecelular2 != null && cliente.telefonecelular2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-cellphone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Celular II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'tel:' + cliente.telefonecelular3" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.telefonecelular3 != null && cliente.telefonecelular3.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-cellphone</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Celular III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'mailto:' + cliente.email" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.email != null && cliente.email.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-email</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  E-mail
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a :href="'mailto:' + cliente.email2" target="_blank">
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
            v-if="cliente.email2 != null && cliente.email2.trim() != ''"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-email</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  E-mail II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="cliente.whatsapp1 != null && cliente.whatsapp1.trim() != ''"
          :href="
            'https://wa.me/' +
            cliente.whatsapp1
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('.', '')
          "
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-whatsapp</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Whatsapp
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="cliente.whatsapp2 != null && cliente.whatsapp2.trim() != ''"
          :href="
            'https://wa.me/' +
            cliente.whatsapp2
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('.', '')
          "
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-whatsapp</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Whatsapp II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="cliente.whatsapp3 != null && cliente.whatsapp3.trim() != ''"
          :href="
            'https://wa.me/' +
            cliente.whatsapp3
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('.', '')
          "
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-whatsapp</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Whatsapp III
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.website !=null) && (this.cliente.website !='')"
          :href="cliente.website"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-web</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nosso Site
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.facebook !=null) && (this.cliente.facebook !='')"
          :href="cliente.facebook"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-facebook</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Facebook
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.facebook2 !=null) && (this.cliente.facebook2 !='')"
          :href="cliente.facebook2"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-facebook</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Facebook II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.instagram !=null) && (this.cliente.instagram !='')"
          :href="cliente.instagram"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-instagram</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Instagram
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a 
          v-if="(this.cliente.instagram2 !=null) && (this.cliente.instagram2 !='')"
          :href="cliente.instagram2"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-instagram</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Instagram II
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.twitter !=null) && (this.cliente.twitter !='')"
          :href="cliente.twitter" 
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-twitter</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Twitter
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.youtube !=null) && (this.cliente.youtube !='')"
          :href="cliente.youtube"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-youtube</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Youtube
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.linkedin !=null) && (this.cliente.linkedin !='')"
          :href="cliente.linkedin"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-linkedin</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Linkedin
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.tiktok !=null) && (this.cliente.tiktok !='')"
          :href="cliente.tiktok"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/tiktok-icon.png')"
                  width="28"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  TikTok
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.olx !=null) && (this.cliente.olx !='')"
          :href="cliente.olx"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/olx-icon.png')"
                  width="30"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  OLX
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.mercadolivre !=null) && (this.cliente.mercadolivre !='')"
          :href="cliente.mercadolivre"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/mercadolivre-icon.png')"
                  width="30"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Mercado Livre
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.shopee !=null) && (this.cliente.shopee !='')"
          :href="cliente.shopee"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/shopee-icon.png')"
                  width="30"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Shopee
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.galeriafotos !=null) && (this.cliente.galeriafotos !='')"
          :href="cliente.galeriafotos"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-camera</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Galeria de Fotos
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.cardapio !=null) && (this.cliente.cardapio !='')"
          :href="cliente.cardapio"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-book-open-variant</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Nosso Cardápio
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.ifood !=null) && (this.cliente.ifood !='')"
          :href="cliente.ifood"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <img 
                  class="mt-3 ml-1"
                  :src="require('@/assets/ifood-icon.png')"
                  width="60"
                />
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  IFood
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.visitavirtual !=null) && (this.cliente.visitavirtual !='')"
          :href="cliente.visitavirtual"
          target="_blank"
        >
          <v-card
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-rotate-360</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                  Tour Virtual
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <a
          v-if="(this.cliente.avaliacao !=null) && (this.cliente.avaliacao !='')"
          @click="abrirAvaliacao"
        >
          <v-card 
            class="mx-auto mb-2 buttoncolor"
            :style="{ color: cliente.corfonte }"
            outlined
            max-width="600"
          >
            <v-row>
              <v-col cols="1">
                <v-icon class="mt-2" large color="white">mdi-thumb-up</v-icon>
              </v-col>
              <v-col cols="11">
                <v-card-text class="textsize">
                      Avalie a Empresa
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </a>

        <v-card
          v-if="(this.cliente.pixcopiaecola !=null) && (this.cliente.pix !='')"
          class="mx-auto mb-2 buttoncolor"
          :style="{ color: cliente.corfonte }"
          outlined
          max-width="600"
          v-clipboard="this.cliente.pixcopiaecola"
          v-clipboard:success="clipboardSuccess"
          v-clipboard:error="clipboardError"
        >
          <v-row>
            <v-col cols="1">
              <img 
                class="mt-3 ml-1"
                :src="require('@/assets/pix-icon.png')"
                width="30"
              />
            </v-col>
            <v-col cols="11">
              <v-card-text class="textsize">
                PIX Copia e Cola
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          class="mx-auto mb-2 buttoncolor"
          :style="{ color: cliente.corfonte }"
          max-width="600"
          outlined
          v-if="(((this.cliente.appapple !=null) && (this.cliente.appapple !='')) || ((this.cliente.appandroid !=null) && (this.cliente.appandroid !='')))"
        >
          <v-card-title>
            <!-- <v-icon large left> mdi-download </v-icon> -->
            <span class="textsize font-weight-light">Baixe nosso aplicativo</span>
          </v-card-title>

          <v-card-text class="textsize">
            <v-row>
            <v-col cols="6"> 
              <a
                :href="cliente.appapple"
                target="_blank"
              >
                <img 
                  :src="require('@/assets/applestore.png')"
                  width="40"
                />
              </a>
            </v-col>

            <v-col cols="6"> 
              <a
                :href="cliente.appandroid"
                target="_blank"
              >
                <img 
                  :src="require('@/assets/googleplay.png')"
                  width="40"
                />
              </a>
            </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <a 
          href="http://www.infinitycard.net.br"
          target="_blank"
        >
          <div>
            <img 
              class="mr-auto ml-auto mt-3"
              :src="require('@/assets/logobranco.png')"
              width="100"
            />
          </div>
          <div class="textlogo white--text">
            Clique e entre em contato
          </div>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
        // <v-card
        //   class="mx-auto mb-2"
        //   color="red"
        //   dark
        //   
        //   v-if="cliente.id == null || cliente.id == ''"
        // >
        //   <v-card-text class="text-h6 font-weight-bold">
        //     <v-icon large left> mdi-alert </v-icon>
        //     Cartão não encontrado!
        //   </v-card-text>
        // </v-card>

import svcClientes from "../services/svcClientes";
import { decriptar, encriptar } from "../services/cripto";

export default {
  data() {
    return {
      cliente: {
        id: "",
        nome: "",
        apelido: "",
        empresa: "",
        cargo: "",
        endereco1: "",
        endereco2: "",
        endereco3: "",
        pix: "",
        telefonefixo1: "",
        telefonefixo2: "",
        telefonefixo3: "",
        telefonenacional: "",
        telefonecelular1: "",
        telefonecelular2: "",
        telefonecelular3: "",
        email: "",
        email2: "",
        whatsapp1: "",
        whatsapp2: "",
        whatsapp3: "",
        facebook: "",
        facebook2: "",
        twitter: "",
        instagram: "",
        instagram2: "",
        linkedin: "",
        tiktok: "",
        youtube: "",
        google1: "",
        google2: "",
        google3: "",
        visitavirtual: "",
        avaliacao: "",
        cardapio: "",
        ifoor: "",
        website: "",
        olx: "",
        mercadolivre: "",
        shopee: "",
        galeriafotos: "",
        appapple: "",
        appandroid: "",
        texto: "",
        login: "",
        senha: "",
        administrador: false,
        imagem: "",
        estiloimagem: "",
        codigoconsulta: "",
        tipofunco: "",
        corfundo: "#000000FF",
        corfonte: "#FFFFFFFF",
      },
      imagem: "",
      snackbar: false,
      text: "",
      timeout: 2000,
      linkconsulta: ""
    };
  },

  methods: {
    clipboardSuccess() {
      this.text = "PIX copiado com sucesso"
      this.snackbar = true
    },

    clipboardError() {
      text = "Erro ao copiar o PIX"
      snackbar = true
    },

    pesquisa(id) {
      svcClientes
        .pesquisar(id)
        .then((resp) => {
          this.cliente = resp.data
          this.imagem = process.env.VUE_APP_URL_IMAGES + "/" + this.cliente.imagem
          if ((this.cliente.estiloimagem == null) || (this.cliente.estiloimagem.trim() == ''))
          {
            this.cliente.estiloimagem = "3"
          }
          if ((this.cliente.google1 == null) || (this.cliente.google1 == ''))
          {
            this.cliente.google1 = "https://www.google.com.br/maps/place/" + this.cliente.endereco1
          }
          if ((this.cliente.google2 == null) || (this.cliente.google2 == ''))
          {
            this.cliente.google2 = "https://www.google.com.br/maps/place/" + this.cliente.endereco2
          }
          if ((this.cliente.google3 == null) || (this.cliente.google3 == ''))
          {
            this.cliente.google3 = "https://www.google.com.br/maps/place/" + this.cliente.endereco3
          }
          this.linkconsulta =
              window.location.protocol +
              "//" +
              window.location.host +
              "/#/view/" +
              this.cliente.codigoconsulta;
        })
        .catch((error) => {
          alert(error);
        });
    },

    adicionarContato() {
      var vCardsJS = require("vcards-js");
      var vCard = vCardsJS();

      //set basic properties shown before
      vCard.firstName = this.cliente.nome.substr(
        0,
        this.cliente.nome.indexOf(" ")
      );
      vCard.lastName = this.cliente.nome.substr(
        this.cliente.nome.indexOf(" ") + 1
      );
      vCard.organization = this.cliente.empresa;

      //link to image
      //vCard.photo.attachFromUrl(this.imagem, "PNG");

      //or embed image
      //vCard.photo.attahFromUrl(this.imagem);
      if ((this.cliente.telefonefixo1 != "") && (this.cliente.telefonefixo1 != null)) {
        this.cliente.telefonefixo1 = "+55 " + this.cliente.telefonefixo1
      }
      if ((this.cliente.telefonefixo2 != "") && (this.cliente.telefonefixo2 != null)) {
        this.cliente.telefonefixo2 = "+55 " + this.cliente.telefonefixo2
      }
      if ((this.cliente.telefonefixo3 != "") && (this.cliente.telefonefixo3 != null)) {
        this.cliente.telefonefixo3 = "+55 " + this.cliente.telefonefixo3
      }
      vCard.workPhone = [
        this.cliente.telefonefixo1,
        this.cliente.telefonefixo2,
        this.cliente.telefonefixo3
      ]
      vCard.title = this.cliente.cargo;
      vCard.url = window.location.protocol +
                  "//" +
                  window.location.host +
                  "?key=" +
                  this.cliente.codigoconsulta;
      vCard.note = this.cliente.texto;

      //set other vitals
      vCard.nickname = this.cliente.apelido;

      //set other phone numbers
      //vCard.homePhone = '312-555-1313';
      if ((this.cliente.telefonecelular1 != "") && (this.cliente.telefonecelular1 != null)) {
        this.cliente.telefonecelular1 = "+55 " + this.cliente.telefonecelular1
      }
      if ((this.cliente.telefonecelular2 != "") && (this.cliente.telefonecelular2 != null)) {
        this.cliente.telefonecelular2 = "+55 " + this.cliente.telefonecelular2
      }
      if ((this.cliente.telefonecelular3 != "") && (this.cliente.telefonecelular3 != null)) {
        this.cliente.telefonecelular3 = "+55 " + this.cliente.telefonecelular3
      }
      vCard.cellPhone = [
        this.cliente.telefonecelular1,
        this.cliente.telefonecelular2,
        this.cliente.telefonecelular3
      ]

      //set email addresses
      vCard.email = this.cliente.email;

      //set logo of organization or personal logo (also supports embedding, see above)
      //vCard.logo.attachFromUrl(this.image, "PNG");

      //set URL where the vCard can be found
      //vCard.source = 'http://mywebpage/myvcard.vcf';

      //set address information
      if ((this.cliente.endereco1 != "") && (this.cliente.endereco1 != null)) {
        vCard.workAddress.label = "Endereço";
        vCard.workAddress.street = this.cliente.endereco1;
        //vCard.homeAddress.city = 'Chicago';
        //vCard.homeAddress.stateProvince = 'IL';
        //vCard.homeAddress.postalCode = '12345';
        //vCard.homeAddress.countryRegion = 'United States of America';
      }

      if ((this.cliente.endereco2 != "") && (this.cliente.endereco2 != null)){
        vCard.homeAddress.label = "Endereço 2";
        vCard.homeAddress.street = this.cliente.endereco2;
        //vCard.homeAddress.city = 'Chicago';
        //vCard.homeAddress.stateProvince = 'IL';
        //vCard.homeAddress.postalCode = '12345';
        //vCard.homeAddress.countryRegion = 'United States of America';
      }

      if ((this.cliente.endereco3 != "") && (this.cliente.endereco3 != null)) {
        vCard.homeAddress.label = "Endereço 3";
        vCard.homeAddress.street = this.cliente.endereco3;
        //vCard.homeAddress.city = 'Chicago';
        //vCard.homeAddress.stateProvince = 'IL';
        //vCard.homeAddress.postalCode = '12345';
        //vCard.homeAddress.countryRegion = 'United States of America';
      }

      //set social media URLs
      vCard.socialUrls["facebook"] =
        "https://www.facebook.com/" + this.cliente.facebook;
      vCard.socialUrls["twitter"] =
        "https://twitter.com/" + this.cliente.twitter;
      vCard.socialUrls["instagram"] =
        "https://www.instagram.com/" + this.cliente.instagram;
      vCard.socialUrls["linkedIn"] =
        "https://www.linkedin.com/in/" + this.cliente.linkedin;
      vCard.socialUrls["youtube"] =
        "https://www.youtube.com/" + this.cliente.youtube;

      //you can also embed photos from files instead of attaching via URL
      //vCard.photo.embedFromFile('..\assets\logobranco.png');
      //vCard.logo.embedFromFile('..\assets\logobranco.png');

      vCard.version = "3.0"; //can also support 2.1 and 4.0, certain versions only support certain fields

      var FileSaver = require("file-saver");
      var blob = new Blob([vCard.getFormattedString()], {
        type: "text/vcard;charset=utf-8",
      });
      FileSaver.saveAs(blob, this.$route.params.id + ".vcf");
    },

    abrirAvaliacao() {
      var win = window.open(this.cliente.avaliacao, '_blank');
      win.focus();
    },

    formataTexto(tipo) { // tipos: w-whatsapp, f-facebook, l-linkedin, e-email
      var texto = "";
      if ((tipo == "w") || (tipo == "e")) {
        texto = this.cliente.nome + "\n" +
                this.cliente.empresa + "\n" +
                this.cliente.cargo + "\n\n" +
                this.cliente.endereco1 + "\n" +
                this.cliente.telefonefixo1 + "\n" +
                this.cliente.telefonecelular1 + "\n" +
                this.cliente.email + "\n" +
                this.cliente.website + "\n\n" +
                "Acesse meu cartão completo em:" + "\n" +
                this.linkconsulta;
      } else {
        texto = this.linkconsulta;
      }

      if ((tipo == "w") || (tipo =="e")) {
        return window.encodeURIComponent(texto);
      } else {
        return texto;
      }
    }, 
  },

  mounted() {
    this.pesquisa(decriptar(this.$route.params.id));
  },
};
</script>

<style scoped>
.bgcolor {
  background-image: url('../assets/background.png');
  background-size: 100%;
  background-repeat: repeat-y;
}
.textsize {
  font-size: medium;
}
.textlogo {
  font-size: smaller;
}
.textbox {
  text-align: center;
}
.multiline {
  white-space: pre-wrap;
}
.margemquadro3 {
  margin-top: -50px;
}
.margemquadro5 {
  margin-top: -40px;
}
.margemquadro7 {
  margin-top: -30px;
}
.margemcard {
  margin-top: -150px;
}
.margemtext {
  margin-top: -10px;
}
.buttoncolor {
  background-color: rgba(255, 255, 255, 0.1);
}
.roundedbutton {
  border-radius: 30px;
}
</style>